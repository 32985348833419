import React from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import {
  Flex,
  Box,
  Button,
  Title1,
  Large,
  colors,
} from 'js-components'
import { Section, WrapperBackground } from '../../components/Wrapper'
import Default from '../../components/Layouts/Default';

const AboutPage = () => (
      <Default>
      <Helmet title="Truly | Request a Demo">
        <link href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,700&display=swap" rel="stylesheet" />
      </Helmet>
      <WrapperBackground
      color={colors.trulyDark}
      background="sf"
      >
        <LandingWrapper>
          <Section pt='84px'>
            <Flex flexDirection={['column', 'row', 'row']}>
              <Box pb='100pt'>
                <Title1 center as="p" color={colors.white} mb="1rem">
                  Thank You.
                </Title1>
                <Large as="h1" mb="16pt" color={colors.white}>
                  Our team will be reaching out to you shortly.
                </Large>
                <Button size="extraLarge" round value="Back to Homepage" onClick={() => navigate('/')} />
              </Box>
            </Flex>
          </Section>
        </LandingWrapper>
    </WrapperBackground>
    </Default>
)

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
`

export default AboutPage
